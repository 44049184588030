// Import libraries
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { motion, useViewportScroll } from 'framer-motion';


// Import navigation context
import NavigationContext from '../context/navigation';


// Import component
import Navigation from './Navigation';


// Component styles
const StyledHeader = styled.header`
    ${props => props.withBanner && css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    `}
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px; /* Equal to banner content margin-top */
    align-items: end;
    justify-items: start;
    padding: 0 var(--padding-right) 0 var(--padding-left);

    & h1 {
        font-family: 'Josefin Sans', var(--font-family);
        ${props => props.withBanner ? css`
            color: #fff;
        ` : css`
            color: #000;
        `}
        font-size: 4rem;
        font-weight: 400;

        & .link:link,
        & .link:visited,
        & .link:hover,
        & .link:focus,
        & .link:active {
            color: currentColor;
            text-decoration: none;
            outline: 0;
        }
    }
`;


const StyledNavigationToggle = styled(motion.div)`
    position: fixed;
    top: 41px;
    right: var(--padding-right);
    z-index: 2;
    display: grid;
    grid-template-columns: 80px;
    grid-template-rows: 80px;
    border-radius: 50%;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    transition: background-color .25s ease;

    & .inner {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 4px);
        grid-row-gap: 8px;
        align-content: center;
        width: 4rem;

        & .bar {
            ${props => (props.scrollY > 0 || props.withBanner || props.navigationIsOpen) ? css`
                background-color: #fff;
            ` : css`
                background-color: #000;
            `}
        }
    }
`;


// Create component
const Header = ({ withBanner }) => {
    const { navigationIsOpen, setNavigationIsOpen } = useContext(NavigationContext);
    const [navigationOpenedOnes, setNavigationOpenedOnes] = useState(navigationIsOpen ? true : false);
    const { scrollYProgress } = useViewportScroll();
    const [scrollY, setScrollY] = useState(scrollYProgress.current);
    
    useEffect(() => {
        const changeScrollY = () => {
            setScrollY(scrollYProgress.current);
        };
        
        const unsubscribeY = scrollYProgress.onChange(changeScrollY);

        return (() => {
            unsubscribeY();
        });
    }, []);
    
    return (
        <React.Fragment>
            <Navigation />
            
            <StyledHeader
                withBanner={withBanner !== undefined && withBanner ? true : false}
            >
                <h1>
                    <Link to="/" className="link">boumedia</Link>
                </h1>

                <StyledNavigationToggle
                    withBanner={withBanner}
                    scrollY={scrollY}
                    navigationIsOpen={navigationIsOpen}
                    animate={scrollY > 0 || navigationIsOpen  ? { backgroundColor: "var(--color-primary)" } : { backgroundColor: "rgba(255, 255, 255, 0)" }}
                    initial={scrollY > 0 || navigationIsOpen ? { backgroundColor: "var(--color-primary)" } : { backgroundColor: "rgba(255, 255, 255, 0)" }}
                    transition={{
                        duration: .1
                    }}
                    onClick={() => {
                        setNavigationIsOpen(!navigationIsOpen);
                        setNavigationOpenedOnes(true);
                    }}
                >
                    <div className="inner">
                        <motion.div
                            animate={navigationIsOpen ? {
                                y: [0, 12, 12, 12],
                                rotate: [0, 0, 45, 45]
                            } : navigationOpenedOnes && {
                                y: [12, 12, 12, 0],
                                rotate: [45, 45, 0, 0]
                            }}
                            transition={{
                                duration: .25
                            }}
                            className="bar"
                        >&nbsp;</motion.div>
                        <motion.div
                            animate={navigationIsOpen ? { opacity: 0 }: { opacity: 1 }}
                            transition={{
                                duration: .01,
                                delay: .125
                            }}
                            className="bar"
                        >&nbsp;</motion.div>
                        <motion.div
                            animate={navigationIsOpen ? {
                                y: [0, -12, -12, -12],
                                rotate: [0, 0, 45, 135]
                            } : navigationOpenedOnes && {
                                y: [-12, -12, -12, 0],
                                rotate: [135, 45, 45, 0, 0]
                            }}
                            transition={{
                                duration: .25
                            }}
                            className="bar"
                        >&nbsp;</motion.div>
                    </div>
                </StyledNavigationToggle>
            </StyledHeader>
        </React.Fragment>
    );
};


// Export component by default
export default Header;